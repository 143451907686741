var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BannerPunish_wrapper" },
    [
      _c(
        "el-tabs",
        {
          on: { click: function($event) {}, "tab-click": _vm.handleClick },
          model: {
            value: _vm.tabName,
            callback: function($$v) {
              _vm.tabName = $$v
            },
            expression: "tabName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "手动惩罚", name: "1" } },
            [
              _c(
                "div",
                { staticClass: "select_wrap" },
                [
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "200px" },
                    attrs: { clearable: true, placeholder: "请输入品牌名称" },
                    model: {
                      value: _vm.HandInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.HandInfo, "name", $$v)
                      },
                      expression: "HandInfo.name"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.searchPage }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary" },
                      on: { click: _vm.exportHandData }
                    },
                    [_vm._v("导出表格")]
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "secondsKill_table",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.HandList, border: "", stripe: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "brandId", label: "品牌ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "品牌名称" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "bonusAmount", label: "惩罚金" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "bonusRemark", label: "惩罚原因" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "implementTime", label: "执行时间" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "bonusAvailableAmount", label: "剩余保证金" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tab-page" },
                [
                  _c("div"),
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.HandInfo.page,
                      "page-size": 10,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.HandTotal
                    },
                    on: { "current-change": _vm.handleCurrentChange }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "任务未达标", name: "2" } },
            [
              _c(
                "div",
                { staticClass: "select_wrap" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "mr10",
                      attrs: { placeholder: "指标类型", clearable: "" },
                      model: {
                        value: _vm.StepInfo.type,
                        callback: function($$v) {
                          _vm.$set(_vm.StepInfo, "type", $$v)
                        },
                        expression: "StepInfo.type"
                      }
                    },
                    _vm._l(_vm.typeOptions, function(item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value }
                      })
                    }),
                    1
                  ),
                  _c("el-input", {
                    staticClass: "ml10",
                    staticStyle: { width: "200px" },
                    attrs: { clearable: true, placeholder: "请输入品牌名称" },
                    model: {
                      value: _vm.StepInfo.name,
                      callback: function($$v) {
                        _vm.$set(_vm.StepInfo, "name", $$v)
                      },
                      expression: "StepInfo.name"
                    }
                  }),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary", icon: "el-icon-search" },
                      on: { click: _vm.searchPage2 }
                    },
                    [_vm._v("搜索")]
                  ),
                  _c(
                    "el-button",
                    {
                      staticClass: "ml10",
                      attrs: { type: "primary" },
                      on: { click: _vm.exportStepData }
                    },
                    [_vm._v("导出表格")]
                  )
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "secondsKill_table",
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.StepList, border: "", stripe: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "brandId", label: "品牌ID" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "name", label: "品牌名称" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "stepName", label: "所属任务阶段" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "type", label: "惩罚指标类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            scope.row.type == "view"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("浏览")
                                ])
                              : _vm._e(),
                            scope.row.type == "fission"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("裂变")
                                ])
                              : _vm._e(),
                            scope.row.type == "enrollment"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("报名")
                                ])
                              : _vm._e(),
                            scope.row.type == "card"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("售券")
                                ])
                              : _vm._e(),
                            scope.row.type == "live_view"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("进直播间客户")
                                ])
                              : _vm._e(),
                            scope.row.type == "live_order_cus"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("直播下订")
                                ])
                              : _vm._e(),
                            scope.row.type == "live_self_order"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("直播自签单")
                                ])
                              : _vm._e(),
                            scope.row.type == "live_other_order"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("直播贡献单")
                                ])
                              : _vm._e(),
                            scope.row.type == "live_self_order_change"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("直播自签转单")
                                ])
                              : _vm._e(),
                            scope.row.type == "live_other_order_change"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("直播贡献转单")
                                ])
                              : _vm._e(),
                            scope.row.type == "process_self_order"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("过程自签单")
                                ])
                              : _vm._e(),
                            scope.row.type == "process_devote_order"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("过程贡献单")
                                ])
                              : _vm._e(),
                            scope.row.type == "seckill_user"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("秒杀客户")
                                ])
                              : _vm._e(),
                            scope.row.type == "thumb"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("集赞客户")
                                ])
                              : _vm._e(),
                            scope.row.type == "appointment"
                              ? _c("el-tag", { attrs: { type: "primary" } }, [
                                  _vm._v("直播预约")
                                ])
                              : _vm._e()
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "指标完成数/任务指标" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c("span", [
                              _vm._v(
                                _vm._s(scope.row.targetComplete) +
                                  "/" +
                                  _vm._s(scope.row.targetAmount)
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "bonusAmount", label: "惩罚金" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "bonusAvailableAmount", label: "剩余保证金" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "tab-page" },
                [
                  _c("div"),
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      "current-page": _vm.StepInfo.page,
                      "page-size": 10,
                      layout: "total, prev, pager, next, jumper",
                      total: _vm.StepTotal
                    },
                    on: { "current-change": _vm.handleCurrentChange2 }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }