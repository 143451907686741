<template>
  <div class="BannerPunish_wrapper">
    <el-tabs v-model="tabName" @click @tab-click="handleClick">
      <el-tab-pane label="手动惩罚" name="1">
        <div class="select_wrap">
          <el-input
            class="ml10"
            :clearable="true"
            placeholder="请输入品牌名称"
            v-model="HandInfo.name"
            style="width:200px"
          ></el-input>
          <el-button
            type="primary"
            class="ml10"
            icon="el-icon-search"
            @click="searchPage"
          >搜索</el-button>
          <el-button type="primary" class="ml10" @click="exportHandData">导出表格</el-button>
        </div>
        <el-table class="secondsKill_table" :data="HandList" border stripe style="width: 100%">
          <el-table-column prop="brandId" label="品牌ID"></el-table-column>
          <el-table-column prop="name" label="品牌名称"></el-table-column>
          <el-table-column prop="bonusAmount" label="惩罚金"></el-table-column>
          <el-table-column prop="bonusRemark" label="惩罚原因"></el-table-column>
          <el-table-column prop="implementTime" label="执行时间"></el-table-column>
          <el-table-column prop="bonusAvailableAmount" label="剩余保证金"></el-table-column>
        </el-table>
        <div class="tab-page">
          <div></div>
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="HandInfo.page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="HandTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
      <el-tab-pane label="任务未达标" name="2">
        <div class="select_wrap">
          <el-select v-model="StepInfo.type" placeholder="指标类型" class="mr10" clearable>
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            class="ml10"
            :clearable="true"
            placeholder="请输入品牌名称"
            v-model="StepInfo.name"
            style="width:200px"
          ></el-input>
          <el-button
            type="primary"
            class="ml10"
            icon="el-icon-search"
            @click="searchPage2"
          >搜索</el-button>
          <el-button type="primary" class="ml10" @click="exportStepData">导出表格</el-button>
        </div>
        <el-table class="secondsKill_table" :data="StepList" border stripe style="width: 100%">
          <el-table-column prop="brandId" label="品牌ID"></el-table-column>
          <el-table-column prop="name" label="品牌名称"></el-table-column>
          <el-table-column prop="stepName" label="所属任务阶段"></el-table-column>
          <el-table-column prop="type" label="惩罚指标类型">
            <template slot-scope="scope">
              <el-tag type="primary" v-if="scope.row.type=='view'">浏览</el-tag>
              <el-tag type="primary" v-if="scope.row.type=='fission'">裂变</el-tag>
              <el-tag type="primary" v-if="scope.row.type=='enrollment'">报名</el-tag>
              <el-tag type="primary" v-if="scope.row.type=='card'">售券</el-tag>
              <el-tag type="primary" v-if="scope.row.type=='live_view'">进直播间客户</el-tag>
              <el-tag type="primary" v-if="scope.row.type=='live_order_cus'">直播下订</el-tag>
              <el-tag type="primary" v-if="scope.row.type=='live_self_order'">直播自签单</el-tag>
              <el-tag type="primary" v-if="scope.row.type=='live_other_order'">直播贡献单</el-tag>
              <el-tag type="primary" v-if="scope.row.type=='live_self_order_change'">直播自签转单</el-tag>
              <el-tag type="primary" v-if="scope.row.type=='live_other_order_change'">直播贡献转单</el-tag>
              <el-tag type="primary" v-if="scope.row.type=='process_self_order'">过程自签单</el-tag>
              <el-tag type="primary" v-if="scope.row.type=='process_devote_order'">过程贡献单</el-tag>
              <el-tag type="primary" v-if="scope.row.type=='seckill_user'">秒杀客户</el-tag>
              <el-tag type="primary" v-if="scope.row.type=='thumb'">集赞客户</el-tag>
              <el-tag type="primary" v-if="scope.row.type=='appointment'">直播预约</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="指标完成数/任务指标">
            <template slot-scope="scope">
              <span>{{scope.row.targetComplete}}/{{scope.row.targetAmount}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="bonusAmount" label="惩罚金"></el-table-column>
          <el-table-column prop="bonusAvailableAmount" label="剩余保证金"></el-table-column>
        </el-table>
        <div class="tab-page">
          <div></div>
          <el-pagination
            background
            @current-change="handleCurrentChange2"
            :current-page="StepInfo.page"
            :page-size="10"
            layout="total, prev, pager, next, jumper"
            :total="StepTotal"
          ></el-pagination>
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 主页面 -->
  </div>
</template>

<script>
import GuideAjax from "@/utils/https/modules/Guide.request.js";
export default {
  name: "BannerPunish", //
  components: {},
  props: {
    activityID: String,
  },
  data() {
    return {
      nodata: "",
      nobool: false,
      typeOptions: [
        { value: "view", label: "浏览" },
        { value: "fission", label: "裂变" },
        { value: "enrollment", label: "报名" },
        { value: "card", label: "售券" },
        { value: "appointment", label: "直播预约" },
        { value: "live_view", label: "进直播间客户" },
        { value: "live_order_cus", label: "直播下订" },
        { value: "live_self_order", label: "直播自签单" },
        { value: "live_other_order", label: "直播贡献单" },
        { value: "live_self_order_change", label: "直播自签转单" },
        { value: "live_other_order_change", label: "直播贡献转单" },
        { value: "process_self_order", label: "过程自签单" },
        { value: "process_devote_order", label: "过程贡献单" },
        { value: "seckill_user", label: "秒杀客户" },
        { value: "thumb", label: "集赞客户" },
      ],
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      HandInfo: {
        actId: 0,
        name: "",
        page: 1,
        size: 10,
        type: "",
      },
      HandList: [],
      HandTotal: 0,
      StepInfo: {
        actId: 0,
        name: "",
        page: 1,
        size: 10,
        type: "",
      },
      StepList: [],
      StepTotal: 0,
      tabName: "1",
    };
  },
  computed: {},
  watch: {},
  methods: {
    //切换tab方法
    handleClick() {
      if (this.tabName == "1") {
        this.HandInfo.page = 1;
        this.getQueryHandBrandBonusList();
      } else if (this.tabName == "2") {
        this.StepInfo.page = 1;
        this.getQueryStepBrandBonusList();
      }
    },
    // 手动惩罚品牌列表页码请求
    handleCurrentChange(val) {
      this.HandInfo.page = val;
      this.getQueryHandBrandBonusList();
    },
    searchPage() {
      this.HandInfo.page = 1;
      this.getQueryHandBrandBonusList();
    },
    // 任务未达标品牌列表页码请求
    handleCurrentChange2(val) {
      this.StepInfo.page = val;
      this.getQueryStepBrandBonusList();
    },
    searchPage2() {
      this.StepInfo.page = 1;
      this.getQueryStepBrandBonusList();
    },
    // 查询品牌惩罚金：手动惩罚品牌列表
    async getQueryHandBrandBonusList() {
      try {
        this.HandInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await GuideAjax.getQueryHandBrandBonusList(this.HandInfo);
        this.HandList = list;
        this.HandTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出品牌惩罚金：手动惩罚品牌列表
    async exportHandData() {
      try {
        const data = await GuideAjax.getExportHandBrandBonusList(this.HandInfo);
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "手动惩罚品牌统计.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 查询品牌惩罚金：任务未达标品牌列表
    async getQueryStepBrandBonusList() {
      try {
        this.StepInfo.actId = this.activityID;
        const {
          data: { list, total },
        } = await GuideAjax.getQueryStepBrandBonusList(this.StepInfo);
        this.StepList = list;
        this.StepTotal = total;
      } catch (error) {
        this.$message.error(error);
      }
    },
    // 导出品牌惩罚金：手动惩罚品牌列表
    async exportStepData() {
      try {
        const data = await GuideAjax.getExportStepBrandBonusList(this.StepInfo);
        const link = document.createElement("a");
        let blob = new Blob([data.data], { type: "application/vnd.ms-excel" });
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "任务未达标品牌统计.xls");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  created() {
    this.getQueryHandBrandBonusList();
    this.getQueryStepBrandBonusList();
  },
  mounted() {},
};
</script>

<style lang="scss">
.BannerPunish_wrapper {
  .select_wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .secondsKill_table {
    margin-top: 20px;
    .el-table__body {
      .cell {
        span {
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
  .tab-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
  }
}
</style>
